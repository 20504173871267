window.$ = (selector) => {
  window.selector = selector
  // console.log("Trying querySelector " + selector)
  return document.querySelector(selector)
}
window.$$ = (selector) => {
  window.selector = selector
  // console.log("Trying querySelectorAll " + selector)
  return document.querySelectorAll(selector)
}

window.ajax = {
  get: (url) => {
    return new Promise(function(resolve, reject) {
      fetch(url)
      .then(data => resolve(data))
      .catch(data => reject(data))
    })
  },

  post: (url, parameters, headers, method = "POST") => {
    return new Promise(function(resolve, reject) {
      parameters = JSON.stringify(parameters)
      base_headers = {
        "X-CSRF-TOKEN": ajax.token(),
        "Content-Type": "application/json",
      }
      all_headers = {...base_headers,...headers}

      fetch(url, {
        method: method,
        headers: all_headers,
        body: parameters
      })
      .then(response => {
        if (response.ok) {
          return resolve(response)
        } else {
          return reject(response)
        }
      })
    })
  },

  patch: (url, parameters, headers) => {
    return ajax.post(url, parameters, headers, "PATCH")
  },

  delete: (url) => {
    return new Promise(function(resolve, reject) {
      fetch(url, {
        method: "DELETE",
        headers: {
          "X-CSRF-TOKEN": ajax.token()
        }
      })
      .then(data => resolve(data))
      .catch(data => reject(data))
    })
  },

  postFormData: (url, formData, headers, method="POST") => {
    return new Promise(function(resolve, reject) {
      base_headers = {
        "X-CSRF-TOKEN": ajax.token(),
      }
      all_headers = {...base_headers,...headers}

      fetch(url, {
        method: method,
        headers: all_headers,
        body: formData
      })
      .then(response => {
        if (response.ok) {
          resolve(response)
        } else {
          reject(response)
        }
      })
      .catch(error => {
        reject("Network error")
      })
    })
  },

  token: () => {
    let meta = $("meta[name=csrf-token]")
    if (meta) {
      return meta.content
    }
  }
}

window.EVERY = {
  track: (name, properties = null) => {
    ahoy.track(name, properties)
  },

  ga_event: (category, action, label = null) => {
    if (typeof ga === 'function') {
      ga('send', 'event', category, action, label)
    }
  },

  ga_pageview: (path) => {
    if (typeof ga === 'function') {
      ga('send', 'pageview', path)
    }
  },

  fb_track: (event_name, parameters = null) => {
    if (typeof fbq === 'function') {
      fbq('track', event_name, parameters)
    }
  }
}

HTMLElement.prototype.animateCSS = function(animation, speed = null) {
  return new Promise((resolve, reject) => {
    const prefix = 'animate__'
    const animationName = `${prefix}${animation}`
    const animationSpeed = `${prefix}${speed}`

    this.classList.add(`${prefix}animated`, animationName)
    if (speed) {
      this.classList.add(animationSpeed)
    }

    function handleAnimationEnd() {
      this.classList.remove(`${prefix}animated`, animationName)
      resolve('Animation ended')
    }

    this.addEventListener('animationend', handleAnimationEnd, { once: true })
  })
}

HTMLElement.prototype.parents = function(selector = null) {
  let parents = []
  let element = this

  while(element.parentNode && element.parentNode.nodeName.toLowerCase() != 'body') {
    element = element.parentNode
    parents.push(element)

    if (selector && element.matches(selector)) {
      return element
    }
  }

  return parents
}

HTMLElement.prototype.listen = function(type, listener) {
  this.removeEventListener(type, listener)
  this.addEventListener(type, listener)
}
