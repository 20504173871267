class Toast {
  static success(message, duration) {
    Toast.showAndHide("success", message, duration)
  }

  static warning(message){
    Toast.showAndHide("warning", message)
  }

  static error(message) {
    Toast.showAndHide("error", message)
  }

  static show(type, message) {
    const toast = document.createElement("div")
    toast.classList.add("toast")
    toast.classList.add("toast-" + type)
    toast.animateCSS("slideInDown", "faster")
    toast.innerText = message
    document.body.appendChild(toast)
  }

  static showAndHide(type, message, duration = 2000) {
    Toast.show(type, message)
    setTimeout(function() {
      Toast.hide()
    }, duration)
  }

  static hide() {
    toast = $(".toast")
    toast.animateCSS("fadeOut")

    toast.addEventListener('animationend', () => {
      toast.parentNode.removeChild(toast)
    });
  }
}

module.exports = Toast
