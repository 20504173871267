import {attachHelpIconHandlers} from "./helpIcon";

const submitUnsubscribeAll = (reason, explanation = "") => {
  let unsubscribeAllGroup = $(".users-preferences .unsubscribe-all-group");
  let unsubscribeAllExplanation = $(".users-preferences .unsubscribe-all-explanation");
  let unsubscribeAllStatus = unsubscribeAllGroup.querySelector(".unsubscribe-all-status");
  ajax.patch(unsubscribeAllGroup.dataset.url, {reason: reason, explanation: explanation})
  .then(response => response.json())
  .then(data => {
    unsubscribeAllGroup.querySelector(".unsubscribe-all-explanation").style.height = "0px";
    unsubscribeAllStatus.querySelector(".success").style.display = "block";
    unsubscribeAllStatus.querySelector(".failure").style.display = "none";
    unsubscribeAllStatus.style.height = "20px";
  })
  .catch(data => {
    let submitButton = unsubscribeAllExplanation.querySelector("button");
    submitButton.disabled = false;
    submitButton.innerHTML = "Submit";
    unsubscribeAllStatus.querySelector(".success").style.display = "none";
    unsubscribeAllStatus.querySelector(".failure").style.display = "block";
    unsubscribeAllStatus.style.height = "20px";
  });
};

const attachEventHandlersToButtonGroup = (buttonGroup) => {
  let buttons = buttonGroup.querySelectorAll("button");
  let url = buttonGroup.dataset.url;
  let statusSpan = buttonGroup.parentElement.lastElementChild;
  buttonGroup.addEventListener("click", (event) => {
    buttons.forEach(button => button.disabled = true); //disable all buttons to prevent multiple submits
    let targetButton = event.target;
    let preference = targetButton.value;
    statusSpan.innerHTML = "...";
    ajax.patch(url, {preference: preference})
    .then(response => response.json())
    .then(data => {
      buttons.forEach(button => button.disabled = false);
      buttonGroup.querySelector("button.selected").classList.remove("selected");
      targetButton.disabled = true;
      targetButton.classList.add("selected");
      statusSpan.innerHTML = "✔";
      statusSpan.className = "status success";
    })
    .catch(data => {
      buttons.forEach(button => button.disabled = false);
      buttonGroup.querySelector("button.selected").disabled = true;
      statusSpan.innerHTML = "❌";
      statusSpan.className = "status failure";
    });
  });

  statusSpan.addEventListener("animationend", () => {
    statusSpan.className = "";
    statusSpan.innerHTML = "";
  })
}

const handleShowMoreCollections = (event) => {
  event.preventDefault();
  let showMoreLink = event.target;
  let showLessLink = showMoreLink.nextElementSibling;
  let moreCollectionsDiv = $("#more-collections");
  ajax.get(showMoreLink.dataset.url)
  .then(response => response.text())
  .then(html => {
    moreCollectionsDiv.innerHTML = html;
    moreCollectionsDiv.querySelectorAll(".btn-grp-container").forEach(attachEventHandlersToButtonGroup);
    moreCollectionsDiv.querySelectorAll(".help-icon").forEach(attachHelpIconHandlers);
    showMoreLink.classList.toggle("hidden");
    showLessLink.classList.toggle("hidden");
    moreCollectionsDiv.style.display = null
    moreCollectionsDiv.animateCSS("fadeIn");
  })
}

const handleShowLessCollections = (event) => {
  event.preventDefault();
  let showLessLink = event.target;
  let showMoreLink = showLessLink.previousElementSibling;
  let moreCollectionsDiv = $("#more-collections");
  moreCollectionsDiv.animateCSS("fadeOut")
  .then(() => {
    moreCollectionsDiv.style.display = "none";
    showMoreLink.classList.toggle("hidden");
    showLessLink.classList.toggle("hidden");
  });
}

const handleUnfollowRecommendedPosts = (event) => {
  let user_hash = $("#user_hash").value
  let post_slug = $("#post_slug").value

  ajax.patch(Routes.recommended_posts_preference_user_path(user_hash), {
    post: post_slug,
    preference: "false"
  })
  .then(data => data.json() )
  .then(data => {
    $(".unfollow-paragraph").innerHTML = "Done!<br>To see what newsletters you follow, <a href='" + Routes.publications_path() + "''>click here</a>."
    $("#confirm-unfollow-recommended-posts").remove()
  })
}

window.addEventListener("DOMContentLoaded", (event) => {
  if ($(".users-preferences") || $(".users-follow_email_pitch")) {
    $$(".btn-grp-container").forEach(attachEventHandlersToButtonGroup);
  }

  if ($(".users-preferences")) {
    $$(".users-preferences .unsubscribe-reason").forEach((reasonButton) => {
      reasonButton.addEventListener("click", (e) => {
        $$(".unsubscribe-reason").forEach(node => {
          node.classList.remove("selected")
        })
        e.target.classList.add("selected")
        let reason = e.target.getAttribute("data-reason");
        if (reason) {
          $(".users-preferences .unsubscribe-all-explanation button").disabled = false;
          $(".users-preferences .unsubscribe-all-explanation button").innerHTML = "Submit";
          $(".users-preferences .unsubscribe-all-explanation textarea").value = "";
          $(".users-preferences .unsubscribe-all-status").style.height = "0px";
          $(".users-preferences .unsubscribe-all-explanation").style.height = "120px";
        } else {
          submitUnsubscribeAll(reason);
        }
      })
    });

    $(".users-preferences .unsubscribe-all-explanation button").addEventListener("click", (e) => {
      e.target.disabled = true;
      e.target.innerHTML = "Submitting...";
      submitUnsubscribeAll($(".users-preferences .unsubscribe-all-group .unsubscribe-reason.selected").dataset.reason, e.target.previousElementSibling.value);
    })
  }

  if ($("#show-more-collections")) {
    $("#show-more-collections").addEventListener("click", handleShowMoreCollections);
    $("#show-less-collections").addEventListener("click", handleShowLessCollections);
  }

  if ($("#confirm-unfollow-recommended-posts")) {
    $("#confirm-unfollow-recommended-posts").addEventListener("click", handleUnfollowRecommendedPosts);
  }
});