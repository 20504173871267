// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import ahoy from "ahoy.js";
import "components/helpers"
import SmartButton from "components/smart_button"
import Unsaved from "components/unsaved"
import Toast from "components/toast"
import Cookie from "components/cookie"
import PaywallManager from "components/paywall_manager"
import ConversionTracker from "components/conversion_tracker"

require("components/posts")
import CommentsBox from "components/comments"
import LoginBox from "components/sessions"
import { EmailBox, PaymentBox, PublishersBox, SurveyBox, BoxNavigator } from "components/subscriptions"
import "components/webfonts"
import "components/gdpr_cookie"
import "components/follow_button"
import "chartkick/chart.js"
import "components/preferences"
import "components/helpIcon"
import * as Routes from 'routes'

window.Cookie = Cookie
window.Toast = Toast
window.SmartButton = SmartButton
window.Unsaved = Unsaved
window.CommentsBox = CommentsBox
window.LoginBox = LoginBox
window.EmailBox = EmailBox
window.PaymentBox = PaymentBox
window.PublishersBox = PublishersBox
window.SurveyBox = SurveyBox
window.BoxNavigator = BoxNavigator
window.ahoy = ahoy
window.Routes = Routes
window.PaywallManager = PaywallManager
window.ConversionTracker = ConversionTracker
