/**
 * File generated by js-routes 2.0.7
 * Based on Rails 6.0.3.7 routes of Every::Application
 */
const __jsr = ((that) => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const Root = that;
    const ModuleReferences = {
        CJS: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            define: (routes) => (module.exports = routes),
            support: () => typeof module === "object",
        },
        AMD: {
            define: (routes) => 
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            define([], function () {
                return routes;
            }),
            support: () => typeof define === "function" && !!define.amd,
        },
        UMD: {
            define: (routes) => {
                if (ModuleReferences.AMD.support()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.support()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            support: () => ModuleReferences.AMD.support() || ModuleReferences.CJS.support(),
        },
        ESM: {
            define: () => null,
            support: () => true,
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const DeprecatedGlobbingBehavior = false;
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !object[this.configuration.special_options_key] &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {};
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, function (str) {
                return encodeURIComponent(str);
            });
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return DeprecatedGlobbingBehavior ? result : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        has_location() {
            return this.is_not_nullable(window) && !!window.location;
        }
        current_host() {
            if (this.has_location()) {
                return window.location.hostname;
            }
            else {
                return null;
            }
        }
        current_protocol() {
            if (this.has_location() && window.location.protocol !== "") {
                return window.location.protocol.replace(/:$/, "");
            }
            else {
                return "http";
            }
        }
        current_port() {
            if (this.has_location() && window.location.port !== "") {
                return window.location.port;
            }
            else {
                return "";
            }
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        namespace(object, namespace, routes) {
            const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split(".")) || [];
            if (parts.length === 0) {
                return routes;
            }
            for (let index = 0; index < parts.length; index++) {
                const part = parts[index];
                if (index < parts.length - 1) {
                    object = object[part] || (object[part] = {});
                }
                else {
                    return (object[part] = routes);
                }
            }
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].support();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            if (!name) {
                return;
            }
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.namespace(Root, null, result);
    if ("ESM") {
        Utils.define_module("ESM", result);
    }
    return result;
})(this);
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /about(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /:id/about(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_publication_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /@:username/about(.:format)
 * @param {any} username
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const about_user_path = __jsr.r({"username":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"@"],[2,[3,"username"],[2,[7,"/"],[2,[6,"about"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"account"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /account/billing(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_billing_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"account"],[2,[7,"/"],[2,[6,"billing"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/advertisements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_advertisement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"advertisements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/advertisements/:advertisement_id/copy/:position/edit(.:format)
 * @param {any} advertisement_id
 * @param {any} position
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_advertisement_edit_copy_path = __jsr.r({"advertisement_id":{"r":true},"position":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"advertisements"],[2,[7,"/"],[2,[3,"advertisement_id"],[2,[7,"/"],[2,[6,"copy"],[2,[7,"/"],[2,[3,"position"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/advertisements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_advertisements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"advertisements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_collections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/editor(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_editor_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editor"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/find_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_find_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"find_user"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/metrics/growth_report(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_growth_report_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"metrics"],[2,[7,"/"],[2,[6,"growth_report"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/comments/:id/hide(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_hide_comment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"hide"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/interactions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_interaction_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"interactions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/interactions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_interactions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"interactions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/landing_page_versions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_landing_page_version_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"landing_page_versions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/landing_page_versions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_landing_page_versions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"landing_page_versions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/live_events/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_live_event_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"live_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/live_events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_live_events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"live_events"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_login_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/metrics(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_metrics_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"metrics"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/metrics/funnel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_metrics_funnel_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"metrics"],[2,[7,"/"],[2,[6,"funnel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/metrics/nbaschez(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_metrics_nbaschez_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"metrics"],[2,[7,"/"],[2,[6,"nbaschez"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/metrics/onboarding(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_metrics_onboarding_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"metrics"],[2,[7,"/"],[2,[6,"onboarding"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/metrics/test_chartable(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_metrics_test_chartable_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"metrics"],[2,[7,"/"],[2,[6,"test_chartable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/metrics/visits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_metrics_visits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"metrics"],[2,[7,"/"],[2,[6,"visits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/metrics/report/monthly/:publication(.:format)
 * @param {any} publication
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_monthly_pub_report_path = __jsr.r({"publication":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"metrics"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"monthly"],[2,[7,"/"],[2,[3,"publication"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/paywall_candidates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_paywall_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"paywall_candidates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/paywall_candidates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_paywall_candidates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"paywall_candidates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_posts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/publications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_publication_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"publications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/publications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_publications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"publications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/queues/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_queue_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"queues"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/queues(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_queues_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"queues"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/metrics/reports/:period(.:format)
 * @param {any} period
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_report_path = __jsr.r({"period":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"metrics"],[2,[7,"/"],[2,[6,"reports"],[2,[7,"/"],[2,[3,"period"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:user_id/logs(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_logs_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts/:id/advanced_delivery(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const advanced_delivery_admin_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"advanced_delivery"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ahoy
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ahoy_engine_path = __jsr.r({}, [2,[7,"/"],[6,"ahoy"]]);

/**
 * Generates rails route to
 * /ahoy/visits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ahoy_engine_visits_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ahoy"]],[7,"/"]],[2,[6,"visits"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ahoy/events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ahoy_engine_events_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ahoy"]],[7,"/"]],[2,[6,"events"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/live_events/:id/announce(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const announce_admin_live_event_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"live_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"announce"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /auth/failure(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auth_failure_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"failure"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /auth/google_oauth2/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auth_google_oauth2_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"google_oauth2"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/author_preference/:username(.:format)
 * @param {any} id
 * @param {any} username
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const author_preference_user_path = __jsr.r({"id":{"r":true},"username":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"author_preference"],[2,[7,"/"],[2,[3,"username"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_path = __jsr.r({}, [2,[7,"/"],[6,"blazer"]]);

/**
 * Generates rails route to
 * /blazer/queries/run(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_run_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"run"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_cancel_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/:id/refresh(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_refresh_query_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refresh"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/tables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_tables_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"tables"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/schema(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_schema_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"schema"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/docs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_docs_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"docs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_new_query_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_edit_query_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_query_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/checks/:id/run(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_run_check_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"checks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"run"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/checks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_checks_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"checks"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /blazer/checks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_new_check_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"checks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/checks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_edit_check_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"checks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/checks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_check_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"checks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/dashboards/:id/refresh(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_refresh_dashboard_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"dashboards"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refresh"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/dashboards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_dashboards_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"dashboards"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /blazer/dashboards/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_new_dashboard_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/dashboards/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_edit_dashboard_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"dashboards"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/dashboards/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_dashboard_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"dashboards"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_root_path = __jsr.r({}, [2,[2,[7,"/"],[6,"blazer"]],[7,"/"]]);

/**
 * Generates rails route to
 * /charges(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const charges_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"charges"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /emails/click/:hash/:info(.:format)
 * @param {any} hash
 * @param {any} info
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const click_emails_path = __jsr.r({"hash":{"r":true},"info":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"emails"],[2,[7,"/"],[2,[6,"click"],[2,[7,"/"],[2,[3,"hash"],[2,[7,"/"],[2,[3,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /c/:slug(.:format)
 * @param {any} slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const collection_path = __jsr.r({"slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"c"],[2,[7,"/"],[2,[3,"slug"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/collection_preference/:collection_slug(.:format)
 * @param {any} id
 * @param {any} collection_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const collection_preference_user_path = __jsr.r({"id":{"r":true},"collection_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"collection_preference"],[2,[7,"/"],[2,[3,"collection_slug"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /collections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const collections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"collections"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /comments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const comments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"comments"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/posts/:id/configure(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const configure_admin_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"configure"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /interactions/create_feedback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_feedback_for_interactions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"interactions"],[2,[7,"/"],[2,[6,"create_feedback"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/create_guest_writer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_guest_writer_admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"create_guest_writer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts/:id/deliver(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deliver_admin_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"deliver"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/queues/deliver(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deliver_admin_queues_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"queues"],[2,[7,"/"],[2,[6,"deliver"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts/:id/deliver_promo(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deliver_promo_admin_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"deliver_promo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts/:id/deliver_test_email(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deliver_test_email_admin_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"deliver_test_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /auth/discord/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const discord_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"discord"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/advertisements/:id/duplicate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const duplicate_admin_advertisement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"advertisements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"duplicate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/advertisements/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_advertisement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"advertisements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/editor/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_editor_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editor"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/interactions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_interaction_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"interactions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/landing_page_versions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_landing_page_version_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"landing_page_versions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/live_events/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_live_event_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"live_events"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/paywall_candidates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_paywall_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"paywall_candidates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/publications/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_publication_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"publications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/queues/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_queue_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"queues"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts/edit_editorial_weights(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_editorial_weights_for_admin_posts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[6,"edit_editorial_weights"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts/:id/email_pitch(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const email_pitch_for_admin_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"email_pitch"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /feeds/:token(.:format)
 * @param {any} token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const feed_path = __jsr.r({"token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"feeds"],[2,[7,"/"],[2,[3,"token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /:id/feed(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const feed_for_publication_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"feed"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts/:id/feedback(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const feedback_for_admin_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"feedback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /field_test
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const field_test_engine_path = __jsr.r({}, [2,[7,"/"],[6,"field_test"]]);

/**
 * Generates rails route to
 * /field_test/experiments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const field_test_engine_experiment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"field_test"]],[7,"/"]],[2,[6,"experiments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /field_test/memberships/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const field_test_engine_membership_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"field_test"]],[7,"/"]],[2,[6,"memberships"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /field_test/participants/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const field_test_engine_legacy_participant_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"field_test"]],[7,"/"]],[2,[6,"participants"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /field_test/participants(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const field_test_engine_participant_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"field_test"]],[7,"/"]],[2,[6,"participants"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /field_test/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const field_test_engine_root_path = __jsr.r({}, [2,[2,[7,"/"],[6,"field_test"]],[7,"/"]]);

/**
 * Generates rails route to
 * /field_tests/finish(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finish_field_tests_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"field_tests"],[2,[7,"/"],[2,[6,"finish"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/follow_email_pitch/:entity_type/:entity_reference(.:format)
 * @param {any} id
 * @param {any} entity_type
 * @param {any} entity_reference
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const follow_email_pitch_user_path = __jsr.r({"id":{"r":true},"entity_type":{"r":true},"entity_reference":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"follow_email_pitch"],[2,[7,"/"],[2,[3,"entity_type"],[2,[7,"/"],[2,[3,"entity_reference"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:id/follow(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const follow_publication_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"follow"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/follow(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const follow_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"follow"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/publications/:id/followers(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const followers_admin_publication_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"publications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"followers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /followings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const following_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"followings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /followings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const followings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"followings"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/posts/:id/free_preview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const free_preview_for_admin_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"free_preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /feeds/global(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const global_feed_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"feeds"],[2,[7,"/"],[2,[6,"global"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/grant_comp_subscription(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const grant_comp_subscription_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"grant_comp_subscription"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const handle_login_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /followings/handle_manage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const handle_manage_followings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"followings"],[2,[7,"/"],[2,[6,"handle_manage"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /subscriptions/hooks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hooks_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"hooks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts/:id/in_page_preview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const in_page_preview_for_admin_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"in_page_preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /community/join(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const join_discord_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"community"],[2,[7,"/"],[2,[6,"join"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /legal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const legal_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"legal"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /letter(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const letter_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"letter"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /likes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const like_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"likes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /likes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const likes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"likes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /community/link(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const link_discord_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"community"],[2,[7,"/"],[2,[6,"link"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const login_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /auth/logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const logout_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /followings/manage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_followings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"followings"],[2,[7,"/"],[2,[6,"manage"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/advertisements/:id/metrics(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const metrics_for_admin_advertisement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"advertisements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"metrics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts/:id/metrics(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const metrics_for_admin_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"metrics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/more_collections(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const more_collection_cards_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"more_collections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/advertisements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_advertisement_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"advertisements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/collections/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_collection_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/editor/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_editor_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editor"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/interactions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_interaction_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"interactions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/landing_page_versions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_landing_page_version_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"landing_page_versions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/live_events/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_live_event_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"live_events"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/paywall_candidates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_paywall_candidate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"paywall_candidates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_post_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/publications/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_publication_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"publications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/queues/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_queue_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"queues"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /subscriptions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_subscription_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /surveys/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_survey_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /paywall_candidates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const paywall_candidate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"paywall_candidates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /pghero
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_path = __jsr.r({}, [2,[7,"/"],[6,"pghero"]]);

/**
 * Generates rails route to
 * /pghero(/:database)/space(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_space_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"space"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/space/:relation(.:format)
 * @param {any} relation
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_relation_space_path = __jsr.r({"database":{},"relation":{"r":true},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"space"],[2,[7,"/"],[2,[3,"relation"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/index_bloat(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_index_bloat_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"index_bloat"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/live_queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_live_queries_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"live_queries"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_queries_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"queries"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/queries/:query_hash(.:format)
 * @param {any} query_hash
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_show_query_path = __jsr.r({"database":{},"query_hash":{"r":true},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"queries"],[2,[7,"/"],[2,[3,"query_hash"],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/system(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_system_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"system"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/cpu_usage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_cpu_usage_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"cpu_usage"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/connection_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_connection_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"connection_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/replication_lag_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_replication_lag_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"replication_lag_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/load_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_load_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"load_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/free_space_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_free_space_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"free_space_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/explain(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_explain_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"explain"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/tune(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_tune_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"tune"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/connections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_connections_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"connections"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/maintenance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_maintenance_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"maintenance"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/kill(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_kill_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"kill"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/kill_long_running_queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_kill_long_running_queries_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"kill_long_running_queries"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/kill_all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_kill_all_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"kill_all"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/enable_query_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_enable_query_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"enable_query_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/reset_query_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_reset_query_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"reset_query_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/system_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_system_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"system_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero(/:database)/query_stats(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_query_stats_path = __jsr.r({"database":{},"format":{}}, [2,[1,[2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[3,"database"]]],[2,[7,"/"],[2,[6,"query_stats"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /pghero/(:database)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pg_hero_root_path = __jsr.r({"database":{},"format":{}}, [2,[2,[2,[7,"/"],[6,"pghero"]],[7,"/"]],[2,[1,[3,"database"]],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /:publication_slug/:id(.:format)
 * @param {any} publication_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post_path = __jsr.r({"publication_slug":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"publication_slug"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /posts/:id/body(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post_body_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"body"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:publication_slug/:id/feedback(.:format)
 * @param {any} publication_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post_feedback_path = __jsr.r({"publication_slug":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"publication_slug"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"feedback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:publication_slug/:id/like_count(.:format)
 * @param {any} publication_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post_like_count_path = __jsr.r({"publication_slug":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"publication_slug"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"like_count"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /:publication_slug/:id/transcript(.:format)
 * @param {any} publication_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const post_transcript_path = __jsr.r({"publication_slug":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"publication_slug"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"transcript"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/preferences(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preferences_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preferences"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts/:id/preview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_admin_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts/:id/preview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_for_admin_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /privacy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const privacy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"privacy"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /@:username(.:format)
 * @param {any} username
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_path = __jsr.r({"username":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"@"],[2,[3,"username"],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publication_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/:id/publication_preference/:publication_slug(.:format)
 * @param {any} id
 * @param {any} publication_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publication_preference_user_path = __jsr.r({"id":{"r":true},"publication_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"publication_preference"],[2,[7,"/"],[2,[3,"publication_slug"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /publications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"publications"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_properties_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_routes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/mailers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"mailers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /paywall_candidates/random(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const random_paywall_candidates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"paywall_candidates"],[2,[7,"/"],[2,[6,"random"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rand(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const random_post_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rand"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/:id/recommended_posts_preference(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recommended_posts_preference_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"recommended_posts_preference"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/interactions/:id/respond_to_feedback(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const respond_to_feedback_admin_interaction_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"interactions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"respond_to_feedback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_posts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /searchjoy
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const searchjoy_path = __jsr.r({}, [2,[7,"/"],[6,"searchjoy"]]);

/**
 * Generates rails route to
 * /searchjoy/searches/overview(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const searchjoy_overview_searches_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"searchjoy"]],[7,"/"]],[2,[6,"searches"],[2,[7,"/"],[2,[6,"overview"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /searchjoy/searches(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const searchjoy_searches_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"searchjoy"]],[7,"/"]],[2,[6,"searches"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /searchjoy/searches/recent(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const searchjoy_searches_recent_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"searchjoy"]],[7,"/"]],[2,[6,"searches"],[2,[7,"/"],[2,[6,"recent"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /searchjoy/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const searchjoy_root_path = __jsr.r({}, [2,[2,[7,"/"],[6,"searchjoy"]],[7,"/"]]);

/**
 * Generates rails route to
 * /admin/posts/:id/sidebar(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidebar_for_admin_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sidebar"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /splash(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const splash_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"splash"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /field_tests/start(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const start_field_tests_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"field_tests"],[2,[7,"/"],[2,[6,"start"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /subscribe(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subscribe_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscribe"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /subscriptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /surveys(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const surveys_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"surveys"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /terms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const terms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"terms"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /thank_you(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const thank_you_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"thank_you"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /emails/track(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const track_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"emails"],[2,[7,"/"],[2,[6,"track"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/track_visited(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const track_visited_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"track_visited"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts/:id/transcript(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transcript_for_admin_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"transcript"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/editor/tweet(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tweet_admin_editor_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editor"],[2,[7,"/"],[2,[6,"tweet"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/unfollow_all_publications(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unfollow_all_publications_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unfollow_all_publications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/unfollow(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unfollow_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"unfollow"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts/:id/unset_scheduled_at(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unset_scheduled_at_for_admin_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unset_scheduled_at"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /unsubscribe(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unsubscribe_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"unsubscribe"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/:id/unsubscribe_all(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unsubscribe_all_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unsubscribe_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/posts/update_editorial_weights(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_editorial_weights_for_admin_posts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[6,"update_editorial_weights"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"account"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/editor/upload_urls(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_urls_admin_editor_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"editor"],[2,[7,"/"],[2,[6,"upload_urls"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /verify(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const verify_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"verify"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /emails/verify(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const verify_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"emails"],[2,[7,"/"],[2,[6,"verify"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /subscriptions/verify(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const verify_subscriptions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[6,"verify"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/weekly_email_preference(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const weekly_email_preference_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"weekly_email_preference"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /write(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const write_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"write"],[1,[2,[8,"."],[3,"format"]]]]]);