document.addEventListener('DOMContentLoaded', function() {
  $$(".publisher-button").forEach(node => {
    node.addEventListener("click", ({currentTarget}) => {
      var current_text = currentTarget.innerText
      var publication_id = currentTarget.getAttribute("data-id")
      var following_enabled = currentTarget.getAttribute("data-following-enabled")

      if (following_enabled == "true") {
        if (confirm("Are you sure you want to unfollow this publication?")) {
          currentTarget.innerText = "Follow"
          currentTarget.classList.remove('following')
          currentTarget.setAttribute("data-following-enabled", false)

          ajax.patch("/users/unfollow", {
            publication_id: publication_id
          })
        }
      } else {
        currentTarget.innerText = "Following"
        currentTarget.classList.add('following')
        currentTarget.setAttribute("data-following-enabled", true)

        ajax.patch("/users/follow", {
          publication_id: publication_id
        })
      }
    })
  })
})