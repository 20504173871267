let Cookie = require("components/cookie")

class PaywallManager {
  post_id = 0
  post_audience = null
  post_excluded_from_paywall = true
  user_status = null
  user_reads = []

  constructor(post_id, post_audience, post_excluded_from_paywall, user_status) {
    this.post_id = post_id
    this.post_audience = post_audience
    this.post_excluded_from_paywall = post_excluded_from_paywall
    this.user_status = user_status
  }

  static init(...args) {
    let paywall = new PaywallManager(...args)

    if (paywall._hasDisplayedHardPaywall()) {
      conversion_tracker.saveHardPaywallTags(paywall.post_id)
      EVERY.track("Post with hard paywall viewed", { post_id: paywall.post_id })
    } else {
      if (paywall._shouldDisplayMeteredPaywall()) {
        paywall._readCookie()
        paywall._incrementUserReads()

        if (paywall._userReadQuotaExceeded()) {
          paywall._show()
        }
        paywall._writeCookie()
      }
    }
  }

  static clearCookie() {
    Cookie.set("every_paywall", "", -1)
  }

  _readCookie() {
    let cookie_content = Cookie.get("every_paywall")

    if (cookie_content == null) {
      cookie_content = {
        user_reads: []
      }
    } else {
      cookie_content = JSON.parse(cookie_content)
    }

    this.user_reads = cookie_content.user_reads
  }

  _writeCookie() {
    let cookie_content = JSON.stringify({
      user_reads: this.user_reads
    })
    let today = new Date()
    let last_day_of_month = new Date(today.getFullYear(), today.getMonth()+1, 0).getDate()
    let days_to_end_of_month = last_day_of_month - today.getDate()

    Cookie.set("every_paywall", cookie_content, days_to_end_of_month)
  }

  _incrementUserReads() {
    if (this.user_reads.indexOf(this.post_id) == -1) {
      this.user_reads.push(this.post_id)
    }
  }

  _hasDisplayedHardPaywall() {
    return this._userPartOfHardPaywall() && this._postPartOfHardPaywall()
  }

  _shouldDisplayMeteredPaywall() {
    return this._userPartOfMeteredPaywall() && this._postPartOfMeteredPaywall()
  }

  _postPartOfHardPaywall() {
    let has_hard_paywall = false

    if (this.post_audience == "paid") {
      has_hard_paywall = true
    }

    return has_hard_paywall
  }

  _postPartOfMeteredPaywall() {
    let has_metered_paywall = false

    if (this.post_audience == "everyone") {
      has_metered_paywall = true
    }

    if (this.post_excluded_from_paywall) {
      has_metered_paywall = false
    }

    return has_metered_paywall
  }

  _userPartOfHardPaywall() {
    let has_hard_paywall = false

    if (this.user_status == null || this.user_status == "free") {
      has_hard_paywall = true
    }

    return has_hard_paywall
  }

  _userPartOfMeteredPaywall() {
    let has_metered_paywall = false

    if (this.user_status == null || this.user_status == "free") {
      has_metered_paywall = true
    }

    return has_metered_paywall
  }

  _userReadQuotaExceeded() {
    return this.user_reads.length > 3
  }

  _show() {
    ajax.get(Routes.post_body_path(this.post_id, { version: "preview", show_ad: true, show_paywall_candidate: true }))
    .then(response => response.text())
    .then(html => {
      $(".post-body").innerHTML = html
      let paywall_candidate_id = $(".post-body #paywall-candidate-id").getAttribute("data-value")
      paywall_candidate_id = parseInt(paywall_candidate_id) || 0

      conversion_tracker.saveMeteredPaywallTags(this.post_id, paywall_candidate_id)
      EVERY.track("Post with metered paywall viewed", { post_id: this.post_id, paywall_candidate_id: paywall_candidate_id })
    })
  }
}

module.exports = PaywallManager
