var Unsaved = {
  selector: null,
  isSubmitting: false,
  dirty: false,

  reset: function() {
    Unsaved.selector = null
    Unsaved.isSubmitting = false
  },

  warn: function(e) {
    if (Unsaved.dirty && !Unsaved.isSubmitting) {
      var confirmationMessage = 'It looks like you have been editing something. If you leave before saving, your changes will be lost.';

      (e || window.event).returnValue = confirmationMessage
      return confirmationMessage
    } else {
      Unsaved.reset()
    }
  },

  track: function(selector) {
    Unsaved.selector = selector

    window.addEventListener("beforeunload", Unsaved.warn)

    document.querySelectorAll(Unsaved.selector).forEach(node => {
      node.addEventListener("change", e => {
        Unsaved.dirty = true
      })

      node.addEventListener("submit", e => {
        Unsaved.isSubmitting = true
      })
    })
  },
}

module.exports = Unsaved
