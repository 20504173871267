var CommentsBox = {
  sub_comment_box: null,
  comment_warning: null,

  init: function() {
    CommentsBox.bind()
    CommentsBox.sub_comment_box = $(".add.subcomment")
    CommentsBox.comment_warning = $(".comment-warning")
    CommentsBox.sub_comment_box.parentNode.removeChild(CommentsBox.sub_comment_box)
    CommentsBox.comment_warning.parentNode.removeChild(CommentsBox.comment_warning)
  },

  bind: function() {
    var node = $("#comment-submit")
    if (node) {
      node.listen("click", CommentsBox.handleCommentButton)
    }

    var node = $("#subcomment-submit")
    if (node) {
      node.listen("click", CommentsBox.handleSubCommentButton)
    }

    var node = $("#comment-body")
    if (node) {
      node.listen("keydown", CommentsBox.handleCommentBody)
    }

    var node = $("#subcomment-body")
    if (node) {
      node.listen("keydown", CommentsBox.handleSubCommentBody)
    }

    $$(".likes-link a").forEach(node => {
      node.listen("click", CommentsBox.handleLikeButtons)
    })

    $$(".reply-link a").forEach(node => {
      node.listen("click", CommentsBox.handleReplyButtons)
    })
  },

  handleCommentButton: function(e) {
    if($("#feedback-confirmation-box")) {
      $("#feedback-confirmation-box").classList.remove("show")
    }

    var post_id = $("#comment-post-id").value
    var comment_id = null
    var body = $("#comment-body").value
    SmartButton.disable(e.target)

    if (current_user == null) {
      e.target.style.display = "none"
      e.target.before(CommentsBox.comment_warning)
      SmartButton.enable(e.target)
      return
    }

    CommentsBox.create(post_id, comment_id, body).then((data) => {
      var comment = document.createElement("div")
      comment.classList.add("comment-wrapper")
      comment.innerHTML = data
      $("#comment-body").value = ""
      $("#comments-box .list").innerHTML = comment.outerHTML + $("#comments-box .list").innerHTML
      $("#comments-box .list .comment").animateCSS("fadeIn")
      SmartButton.enable(e.target)
      CommentsBox.bind()
    }).catch((data) => {
      console.log(data)
      Toast.error("There was an issue saving your comment. Please retry in a few.")
      SmartButton.enable(e.target)
    })
  },

  handleSubCommentButton: function(e) {
    var post_id = $("#comment-post-id").value
    var comment_id = $("#subcomment-comment-id").value
    var body = $("#subcomment-body").value
    SmartButton.disable(e.target)

    if (current_user == null) {
      e.target.style.display = "none"
      e.target.before(CommentsBox.comment_warning)
      SmartButton.enable(e.target)
      return
    }

    CommentsBox.create(post_id, comment_id, body).then((data) => {
      var sub_comments = $("#comment-" + comment_id).parents(".comment-wrapper").querySelector(".sub-comments")
      sub_comments.innerHTML += data
      var comments = sub_comments.querySelectorAll(".comment")
      var last_comment = comments[comments.length - 1]

      $(".add.subcomment").style.display = "none"
      document.getElementsByTagName("html")[0].style.scrollBehavior = "smooth"
      window.scrollTo(0, last_comment.offsetTop)
      document.getElementsByTagName("html")[0].style.scrollBehavior = null
      last_comment.animateCSS("fadeIn")
      SmartButton.enable(e.target)
      CommentsBox.bind()
    }).catch((data) => {
      console.log (data)
      Toast.error("There was an issue saving your comment. Please retry in a few.")
      SmartButton.enable(e.target)
    })
  },

  handleCommentBody: function(e) {
    if ((e.metaKey || e.ctrlKey) && e.keyCode == 13) {
      $("#comment-submit").click()
    }
  },

  handleSubCommentBody: function(e) {
    if ((e.metaKey || e.ctrlKey) && e.keyCode == 13) {
      $("#subcomment-submit").click()
    }
  },

  handleLikeButtons: function(e) {
    e.preventDefault()
    var like_action = this.getAttribute("data-action")
    var like_id = this.getAttribute("data-id")
    var comment = this.parents(".comment")
    var comment_id = comment.querySelector(".comment-id").value

    if (current_user != null) {
      if (like_action == "like") {
        CommentsBox.like(comment_id).then((data) => {
          $("#comment-" + comment_id + " .likes-link").innerHTML = data
          CommentsBox.bind()
        })
      } else if (like_action == "unlike") {
        CommentsBox.unlike(like_id).then((data) => {
          $("#comment-" + comment_id + " .likes-link").innerHTML = data
          CommentsBox.bind()
        })
      }
    }
  },

  handleReplyButtons: function(e) {
    e.preventDefault()
    var comment = this.parents(".comment")
    var comment_id = comment.querySelector(".root-comment-id").value
    var username = comment.querySelector(".comment-username").value

    comment.querySelector(".subcomment-box").append(CommentsBox.sub_comment_box)
    comment.querySelector("#subcomment-comment-id").value = comment_id
    comment.querySelector("#subcomment-body").focus()
    comment.querySelector("#subcomment-body").value = username + " "
    $(".add.subcomment").style.display = null
  },

  create: function(post_id, comment_id, body) {
    return new Promise(function(resolve, reject) {
      ajax.post(Routes.comments_path(), {
        comment: {
          post_id: post_id,
          comment_id: comment_id,
          body: body
        }
      })
      .then(response => response.text())
      .then(data => {
        const element = document.getElementById('comments-count');

        if(element) {
          let count = element.innerHTML ? parseInt(element.innerHTML) : 0;
          count++;
          element.innerHTML = count;
        }

        resolve(data)
      }).catch(data => {
        reject(data)
      })
    })
  },

  like: function(comment_id) {
    return new Promise(function(resolve, reject) {
      ajax.post(Routes.likes_path(), {
        like: {
          comment_id: comment_id
        }
      })
      .then(response => response.text())
      .then(data => {
        resolve(data)
      }).catch(data => {
        reject(data)
      })
    })
  },

  unlike: function(id) {
    return new Promise(function(resolve, reject) {
      ajax.delete(Routes.like_path(id))
      .then(response => response.text())
      .then(data => {
        resolve(data)
      }).catch(data => {
        reject(data)
      })
    })
  }
}

module.exports = CommentsBox
