let Cookie = require("components/cookie")

class ConversionTracker {
  controller = null
  action = null
  metered_paywall_tags = {}
  hard_paywall_tags = {}

  constructor(controller, action) {
    this.controller = controller
    this.action = action
  }

  init() {
    this._readCookie()
    if (!this._onSubscribePage()) {
      // We need to nullify direct conversion tags on every page load, except the subscribe page. These conversions should only be counted if the user directly went from the page to the subscribe flow.
      this._nullifyDirectConversionTags()
      this._writeCookie()
    }
  }

  saveHardPaywallTags(post_id) {
    this.hard_paywall_tags = {
      post_id: post_id
    }
    this._writeCookie()
  }

  saveMeteredPaywallTags(post_id, paywall_candidate_id) {
    this.metered_paywall_tags = {
      post_id: post_id,
      paywall_candidate_id: paywall_candidate_id
    }
    this._writeCookie()
  }

  getConversionTags() {
    return this._serialize()
  }

  clearConversionTags() {
    this._deleteCookie()
    this.metered_paywall_tags = {}
    this.hard_paywall_tags = {}
  }

  _onSubscribePage() {
    return this.controller == "subscriptions" && this.action == "new"
  }

  _nullifyDirectConversionTags() {
    this.metered_paywall_tags = {}
    this.hard_paywall_tags = {}
  }

  _serialize() {
    let info = {
      metered_paywall_tags: this.metered_paywall_tags,
      hard_paywall_tags: this.hard_paywall_tags,
    }
    info = JSON.stringify(info)

    return info
  }

  _readCookie() {
    let cookie_value = Cookie.get("every_conversion_tracker")
    cookie_value = JSON.parse(cookie_value)

    if (cookie_value != null) {
      this.metered_paywall_tags = cookie_value["metered_paywall_tags"]
      this.hard_paywall_tags = cookie_value["hard_paywall_tags"]
    }
  }

  _writeCookie() {
    let cookie_value = this._serialize()

    Cookie.set("every_conversion_tracker", cookie_value)
  }

  _deleteCookie() {
    Cookie.set("every_conversion_tracker", "", -1)
  }
}

module.exports = ConversionTracker
