var LoginBox = {
  init: function() {
    $(".email-box #login-button-error").style.display = "none"
    $(".email-box #login-button-success").style.display = "none"
    LoginBox.bindFields()
  },

  bindFields: function() {
    $("#email-field").addEventListener("keyup", (e) => {
      if (e.keyCode == 13) {
        $("#login-button").click()
      }
    })

    $("#login-button").addEventListener("click", ({currentTarget}) => {
      SmartButton.disable($("#login-button"))
      var email = $("#email-field").value

      if (email == "") {
        $(".email-box #login-button-error").style.display = null
        $(".email-box #login-button-error").innerHTML = "No email entered!"

        SmartButton.enable($("#login-button"))
      } else {
        LoginBox.login()
      }
    })
  },

  login: function() {
    ajax.post(Routes.handle_login_path(), {
      email: $("#email-field").value
    })
    .then(response => response.json())
    .then(data => {
      $(".email-box #login-button-error").style.display = "none"
      $(".email-box #login-button-success").style.display = "none"

      if (data.error == "User does not exist") {
        $(".email-box #login-button-error").style.display = null
        $(".email-box #login-button-error").innerHTML = "We don't have a user with that email address! Would you like to <a href='/subscribe/'>sign up</a>?"
      } else {
        if($("#sign-form") && $("#sign-success")) {
          $("#sign-form").classList.toggle("hidden");
          $("#sign-success").classList.toggle("hidden")
        } else {
          $(".email-box #login-button-success").style.display = null
          $(".email-box #login-button-success").innerHTML = "Login link sent! Check your email."
        }
      }

      SmartButton.enable($("#login-button"))
    })
  },
}

module.exports = LoginBox
