var Cookie = {
  get: (key) => {
    let cookie = document.cookie.split('; ').find(row => row.startsWith(key))
    let value = null

    if (cookie) {
      value = cookie.split('=')[1]
    }

    return value
  },

  set: (key, value, days=null) => {
    let cookie = `${key}=${value}; path=/`

    if (days) {
      let date = new Date()
      date.setDate(date.getDate() + days)
      cookie += `; expires=${date.toUTCString()}`
    }

    document.cookie = cookie
  },

  delete: (key) => {
    document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  }
}

module.exports = Cookie
