var SmartButton = {
  disable: function(button) {
    if (button.disabled == false) {
      button.disabled = true
      button.setAttribute("data-original-text", button.innerText)
      button.innerText = "..."
    }
  },

  enable: function(button) {
    if (button.disabled == true) {
      button.disabled = false
      button.innerText = button.getAttribute("data-original-text")
      button.setAttribute("data-original-text", null)
    }
  }
}

module.exports = SmartButton
